<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.config") }}
      <v-spacer></v-spacer>
      <v-btn
        color="purple"
        small
        outlined
        @click="showDialogQuantityConfigExcel"
        class="mr-2"
      >
        {{ $t("labels.config_excel") }}
      </v-btn>
      <v-btn color="red darken-1" small outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 245px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.sku')"
                  :placeholder="$t('labels.sku')"
                  name="sku"
                  sort-name="sku"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.barcode')"
                  :placeholder="$t('labels.barcode')"
                  name="barcode"
                  sort-name="barcode"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.quantity')"
                  :placeholder="$t('labels.quantity')"
                  name="quantity"
                  sort-name="quantity"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.note')"
                  :placeholder="$t('labels.note')"
                  name="note"
                  sort-name="note"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.name')"
                  :placeholder="$t('labels.name')"
                  name="name"
                  sort-name="name"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <SelectFilter
                  :label="$t('labels.image')"
                  :placeholder="$t('labels.image')"
                  name="image"
                  sort-name="url_images"
                  has-sort
                  :options="imageOptions"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.goods_description')"
                  :placeholder="$t('labels.goods_description')"
                  name="description"
                  sort-name="description"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <template>
                <th class="text-center" style="min-width: 100px"></th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="text-center">
              <td class="">{{ item.sku }}</td>
              <td class="">{{ item.customer_goods_barcode }}</td>
              <td class="">{{ item.quantity }}</td>
              <td class="">{{ item.note }}</td>
              <td class="">{{ item.name }}</td>
              <td class="">
                <ImageViewer v-if="item.url_images" :url="item.url_images" />
              </td>
              <td class="">{{ item.description }}</td>
              <template>
                <td class="">
                  <v-btn
                    x-small
                    color="warning"
                    v-if="isShowBtnCancel(item)"
                    @click="cancel(item)"
                  >
                    {{ $t("labels.cancel_reserved_goods") }}
                  </v-btn>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions class="w-100 justify-center">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="6"
      ></v-pagination>
    </v-card-actions>

    <v-dialog v-model="dialogQuantityConfigExcel" persistent max-width="100vw">
      <DialogQuantityConfigExcel
        v-if="dialogQuantityConfigExcel"
        @cancel="hideDialogQuantityConfigExcel"
        @refreshData="refreshData"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { IMAGE_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "DialogQuantityConfig",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DialogQuantityConfigExcel: () =>
      import("@/components/pos_goods/DialogQuantityConfigExcel"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    isLoading: false,
    hasChange: false,
    dialogQuantityConfigExcel: false,
    page: 1,
    totalPage: 1,
    filters: {
      status: 1,
    },
    imageOptions: [...IMAGE_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    closeDialog() {
      if (this.hasChange) {
        this.$emit("refreshData", true);
      }
      this.$emit("cancel", true);
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    changeStatus(status) {
      this.filters = { ...this.filters, status: status };
    },
    btnStatusColor(status) {
      if (status === this.filters.status) {
        return "primary";
      }
      return "default";
    },
    refreshData() {
      this.hasChange = true;
      this.getList();
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-transfer-quantity-config-list", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 100),

    isShowBtnCancel(item) {
      const { me } = window;
      return item.status === 1 && item.id_creator === me.identity_id;
    },

    showDialogQuantityConfigExcel() {
      this.dialogQuantityConfigExcel = true;
    },
    hideDialogQuantityConfigExcel() {
      this.dialogQuantityConfigExcel = false;
    },

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-giu-ton.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-reserved-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async cancel(item) {
      if (!confirm(this.$t("messages.are_you_confirm_cancel_reserved_goods"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/cancel-goods-reserved", {
          id: item.id,
        });
        this.isLoading = false;
        this.$vToastify.success(
          this.$t("messages.cancel_reserved_goods_success")
        );
        this.hasChange = true;
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
